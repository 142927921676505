import React, { FunctionComponent } from "react";
import { bem } from "../../classnames";

import { Header, MobileMenu, ResponsiveContentSwitch, Underline } from "../../components";
import { strings } from "../../translations";
import { PriceListItem } from "./PriceListItem";
import "./PriceList.scss";

const cxDesktop = bem("desktop-price-list");

const cxMobile = bem("mobile-price-list");

export const PriceList: FunctionComponent = () => (
    <ResponsiveContentSwitch
        desktop={
            <div className={cxDesktop()}>
                <Header text={strings["priceList"]} />
                <div className={cxDesktop("content_wrapper")}>
                    <table>
                        <tbody>
                            {strings["priceLineList"].map((priceLine) => (
                                <>
                                    {(priceLine.priceItemList.length === 0) ? (
                                        <tr>
                                            <td colSpan={2}>{priceLine.title}</td>
                                        </tr>
                                    ) : (
                                        <>
                                            <tr>
                                                <td className={cxDesktop("title_cell")}>
                                                    {priceLine.title}
                                                    <Underline />
                                                    {priceLine.priceItemList[0].price}
                                                </td>
                                                <td className={cxDesktop("price_unit_cell")}>
                                                    {priceLine.priceItemList[0].priceUnit}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <td colSpan={2}>&nbsp;</td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        }
        mobile={
            <div className={cxMobile()}>
                <MobileMenu leftContent={strings["priceList"]}>
                    <div className={cxMobile("list")}>
                        {strings["mobilePriceList"].map((item, index) => (
                            <PriceListItem key={index} title={item.title} priceText={item.priceText} />
                        ))}
                    </div>
                </MobileMenu>
            </div>
        }
    />
);
