import React, { FunctionComponent } from "react";

import { bem } from "../../classnames";
import { MobileMenu } from "../../components/Menu/MobileMenu";
import { strings } from "../../translations";
import img from "./assets/profile.jpeg"
import "./Profile.scss";

const cx = bem("mobile-profile");

export const MobileProfile: FunctionComponent = () => (
    <div className={cx()}>
        <MobileMenu leftContent={strings["profile"]}>
            <div className={cx("content")}>
                <div className={cx("text")}>
                    <p>
                        {strings["profileMainText"]}
                    </p>
                    <p>
                        {strings["profileSecondaryText"]}
                    </p>
                </div>
                <img
                    alt="Fotografie Marie Machanderové"
                    className={cx("image")}
                    src={img}
                />
                <br />
                <div className={cx("text")}>
                    <h3>{strings["references"].toUpperCase()}:</h3>
                    {strings["referencesList"].map((reference) => (
                        <div className={cx("reference")}>
                            {reference.title}:<br />
                            <div className={cx("second-line")}>– {reference.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </MobileMenu>
    </div>
);
