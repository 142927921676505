import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./MobileMenuItem.scss";

type MobileMenuItemProps = {
    text: string;
    link: string;
    onClick?: () => void;
}

export const MobileMenuItem: FunctionComponent<MobileMenuItemProps> = ({ text, link, onClick }) => (
    <Link
        className="mobile-menu-item"
        to={link}
        onClick={onClick}
    >
        {text}
    </Link>
);
