import React, { FunctionComponent } from "react";

import { bem } from "../../classnames";
import { Header, MobileMenu, ResponsiveContentSwitch } from "../../components";
import { strings } from "../../translations";
import "./Services.scss";

const cxDesktop = bem("services-desktop");
const cxMobile = bem("services-mobile");

export const Services: FunctionComponent = () => (
    <ResponsiveContentSwitch
        desktop={
            <div className={cxDesktop()}>
                <Header text={strings["services"]} />
                <div className="services__content_wrapper">
                    <div className="services__content">
                        {strings["serviceList"].map((service) => (
                            <>
                                <div className="services__title">{service.title}</div>
                                <div className="services__subtitle">– {service.description}</div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        }
        mobile={
            <div className={cxMobile()}>
                <MobileMenu leftContent={strings["services"]}>
                    <div className="services__content_wrapper">
                        <div className="services__content">
                            {strings["serviceList"].map((service) => (
                                <>
                                    <div className="services__title">{service.title}</div>
                                    <div className="services__subtitle">– {service.description}</div>
                                </>
                            ))}
                        </div>
                    </div>
                </MobileMenu>
            </div>
        }
    />
);
