import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./DesktopMenuItem.scss";

type DesktopMenuItemProps = {
    text: string;
    link: string;
    onClick?: () => void;
}

export const DesktopMenuItem: FunctionComponent<DesktopMenuItemProps> = ({ text, link, onClick }) => (
    <Link
        className="desktop-menu-item"
        to={link}
        onClick={onClick}
    >
        {text}
    </Link>
);
