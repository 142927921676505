import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./Logo.scss";

export const DesktopLogo: FunctionComponent = () => (
    <Link className="desktop-logo" to="/" />
);

export const MobileLogo: FunctionComponent = () => (
    <Link className="mobile-logo" to="/" />
);
