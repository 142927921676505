import React from "react";

import { Translation } from "./types";
import { general } from "./general";
import { NON_BREAKING_SPACE } from "./constants";

export const czech: Translation = {
    profile: "Profil",
    services: "Služby",
    priceList: "Ceník",
    contact: "Kontakt",
    phytopathologist: "Fytopatolog",
    nameText: "Marie Machanderová",
    footerText: "© Marie Machanderová; Stránky nepoužívají cookies, proto není třeba souhlasu.",
    authorText: "Pavel Pospíšil",
    titledName: `Ing.${NON_BREAKING_SPACE}Marie Machanderová,${NON_BREAKING_SPACE}Ph.D.`,
    ico: "IČO: 751 56 555",
    dic: "DIČ: CZ9052014609",
    country: undefined,
    references: "Reference",
    referencesList: [
        {
            title: "ABIES zahrada, park, zeleň (Zámek Dobříš)",
            description: `Kontrola zdravotního stavu porostů zimostrázu a${NON_BREAKING_SPACE}konzultační činnost v${NON_BREAKING_SPACE}ochraně rostlin`,
        },
        {
            title: "Gardenline s.r.o.",
            description: <span>Rostlinolékařský dozor realizace projektu <i>Slatiňany – šlechtická škola v&nbsp;přírodě</i></span>,
        },
        {
            title: `GARPEN ZAHRADNICKÁ spol. s${NON_BREAKING_SPACE}r.o.`,
            description: "Kontrola zdravotního stavu nově vysázených vzrostlých stromů",
        },
        {
            title: "GHMP (Zámek Trója)",
            description: `Pravidelný monitoring zdravotního stavu okrasných a${NON_BREAKING_SPACE}ovocných roslin`,
        },
        {
            title: "NPÚ (Květná zahrada Kroměříž)",
            description: `Pravidelný monitoring zdravotního stavu porostů zimostrázu a${NON_BREAKING_SPACE}konzultační činnost v${NON_BREAKING_SPACE}ochraně rostlin`,
        },
        {
            title: "NPÚ (Zámek Libochovice)",
            description: `Kontrola zdravotního stavu porostů zimostrázu a${NON_BREAKING_SPACE}konzultační činnost v${NON_BREAKING_SPACE}ochraně rostlin`,
        },
    ],
    profileMainText: `Absolventka doktorského studia oboru rostlinolékařství na${NON_BREAKING_SPACE}Mendelově univerzitě v${NON_BREAKING_SPACE}Brně. Nabyté znalosti ze${NON_BREAKING_SPACE}studia rozšířila o${NON_BREAKING_SPACE}teoretické i${NON_BREAKING_SPACE}praktické zkušenosti na${NON_BREAKING_SPACE}stážích ve${NON_BREAKING_SPACE}výzkumných ústavech a${NON_BREAKING_SPACE}laboratořích v${NON_BREAKING_SPACE}Německu, USA, Chile a${NON_BREAKING_SPACE}v českých produkčních školkách.`,
    profileSecondaryText: (
        <span>Vlastní disertační práce byla zaměřena na&nbsp;patogenyrodu <i>Buxus</i> a&nbsp;jeho ochranu.</span>
    ),
    serviceList: [
        {
            title: "Rostlinolékařská diagnostika",
            description: `odběr vzorků symptomatických rostlin, identifikace patogenů a${NON_BREAKING_SPACE}škůdců okrasných rostlin pomocí laboratorních metod`,
        },
        {
            title: "Návrhy ochranných opatření",
            description: `doporučení použití vhodných biologických přípravků nebo pesticidů, jejich kombinací a${NON_BREAKING_SPACE}dávkování`,
        },
        {
            title: "Rostlinolékařský dozor",
            description: "při realizaci vegetačních úprav",
        },
        {
            title: "Aplikace chemické ochrany",
            description: `samotná aplikace chemické ochrany na${NON_BREAKING_SPACE}základě osvědčení III.${NON_BREAKING_SPACE}stupně`,
        },
        {
            title: "Odborné semináře",
            description: `sdílení poznatků formou prezentací z${NON_BREAKING_SPACE}oblasti chorob a${NON_BREAKING_SPACE}škůdců okrasných rostlin`,
        },
    ],
    priceLineList: [
        { title: `Kontrola na${NON_BREAKING_SPACE}místě a${NON_BREAKING_SPACE}vypracování zprávy je${NON_BREAKING_SPACE}účtováno na${NON_BREAKING_SPACE}základě odpracovaného času.`, priceItemList: [] },
        { title: `Analýza vzorků půdy a${NON_BREAKING_SPACE}vody je${NON_BREAKING_SPACE}účtována v${NON_BREAKING_SPACE}závislosti na${NON_BREAKING_SPACE}jejím rozsahu.`, priceItemList: [] },
        { title: "", priceItemList: [] },
        {
            title: `Odebrání a${NON_BREAKING_SPACE}analýza vzorků`, priceItemList: [
                { price: "400", priceUnit: "Kč/ks" },
            ]
        },
        {
            title: "Izolace houbového patogenu", priceItemList: [
                { price: "900", priceUnit: "Kč/ks" },
            ]
        },
        {
            title: <span><i>Phytophtora</i>&nbsp;&nbsp;test</span>, priceItemList: [
                { price: "500", priceUnit: "Kč/ks" },
            ]
        },
        { title: "", priceItemList: [] },
        { title: `Uvedené ceny jsou orientační, konečná cena dle${NON_BREAKING_SPACE}dohody a${NON_BREAKING_SPACE}rozsahu práce.`, priceItemList: [] },
    ],
    address: general.address,
    phoneNumber: general.phoneNumber,
    mail: general.mail,
    mobilePriceList: [
        { title: `Kontrola na${NON_BREAKING_SPACE}místě a${NON_BREAKING_SPACE}vypracování zprávy je${NON_BREAKING_SPACE}účtováno na${NON_BREAKING_SPACE}základě odpracovaného času.` },
        { title: `Analýza vzorků půdy a${NON_BREAKING_SPACE}vody je${NON_BREAKING_SPACE}účtována v${NON_BREAKING_SPACE}závislosti na${NON_BREAKING_SPACE}jejím rozsahu.` },
        { title: `Odebrání a${NON_BREAKING_SPACE}analýza vzorků`, priceText: "400 Kč/ks" },
        { title: "Izolace houbového patogenu", priceText: "900 Kč/ks" },
        { title: <span><i>Phytophtora</i>&nbsp;&nbsp;test</span>, priceText: "500 Kč" },
        { title: `Uvedené ceny jsou orientační, konečná cena dle${NON_BREAKING_SPACE}dohody a${NON_BREAKING_SPACE}rozsahu práce.` },
    ],
};
