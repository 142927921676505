import React, { FunctionComponent, ReactElement } from "react";

import { bem } from "../../../classnames";
import "./PriceListItem.scss";

export type PriceListItemProps = {
    title: string | ReactElement;
    priceText?: string;
}

const cx = bem("price-list-item");

export const PriceListItem: FunctionComponent<PriceListItemProps> = ({ priceText, title }) => (
    <div className={cx()}>
        <div>{title}</div>
        {priceText ? (
            <div>{priceText}</div>
        ) : null}
    </div>
);
