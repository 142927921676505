import React, { FunctionComponent } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Footer, Page } from "./components";
import { Homepage, Profile, PriceList, Contact, Services } from "./screens";

const Container: FunctionComponent = ({children}) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
        <Page>
            {children}
        </Page>
        <Footer />
    </div>
);

const router = createBrowserRouter([
    {path: "/", element: <Container><Homepage /></Container>},
    {path: "/profil", element: <Container><Profile /></Container>},
    {path: "/sluzby", element: <Container><Services /></Container>},
    {path: "/cenik", element: <Container><PriceList /></Container>},
    {path: "/kontakt", element: <Container><Contact /></Container>},
    { path: "*", element: <Container><Homepage /></Container> },
]);

export const Router = () => (
    <RouterProvider router={router} />
);
