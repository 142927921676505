import React, { FunctionComponent } from "react";

import { bem } from "../../classnames";
import { DesktopMenuItem } from "../MenuItem";
import { LanguageDesktopMenuItem } from "../LanguageDesktopMenuItem";
import { strings } from "../../translations";
import "./Menu.scss";

const cx = bem("desktop-menu");

export const DesktopMenu: FunctionComponent = () => (
    <ul className={cx()}>
        <li className={cx("item")}>
            <DesktopMenuItem text={strings["profile"]} link="/profil" />
        </li>
        <li className={cx("item")}>
            <DesktopMenuItem text={strings["services"]} link="/sluzby" />
        </li>
        <li className={cx("item")}>
            <DesktopMenuItem text={strings["contact"]} link="/kontakt" />
        </li>
        <li className={cx("item")}>
            <DesktopMenuItem text={strings["priceList"]} link="/cenik" />
        </li>
        <li className={cx("item")}>
            <LanguageDesktopMenuItem />
        </li>
    </ul>
);
