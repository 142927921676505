import React, { FunctionComponent } from "react";
import { bem } from "../../classnames";

import { Header, MobileMenu, ResponsiveContentSwitch } from "../../components";
import { strings } from "../../translations";

import "./Contact.scss";

const cxDesktop = bem("contact-desktop");
const cxMobile = bem("contact-mobile");

export const Contact: FunctionComponent = () => (
    <ResponsiveContentSwitch
        desktop={
            <div className={cxDesktop()}>
                <Header text={strings["contact"]} />
                <div className={cxDesktop("content-wrapper")}>
                    <div className={cxDesktop("content")}>
                        <div className={cxDesktop("name")}>{strings["titledName"]}</div>
                        {strings["ico"] ? (
                            <div className={cxDesktop("ico")}>{strings["ico"]}</div>
                        ) : null}
                        {strings["dic"] ? (
                            <div className={cxDesktop("dic")}>{strings["dic"]}</div>
                        ) : null}
                        <br />
                        <div className={cxDesktop("address")}>{strings["address"]}</div>
                        {strings["country"] ? (
                            <div>{strings["country"]}</div>
                        ) : null}
                        <div>
                            <a className={cxDesktop("phone-link")} href="tel:+420723812041">{strings["phoneNumber"]}</a>
                        </div>
                        <div>
                            <a className={cxDesktop("mail-link")} href="mailto:marie@fytopatolog.cz">{strings["mail"]}</a>
                        </div>
                    </div>
                </div>
            </div>
        }
        mobile={
            <div className={cxMobile()}>
                <MobileMenu leftContent={strings["contact"]}>
                    <div className={cxMobile("content-wrapper")}>
                        <div className={cxMobile("content")}>
                            <div className={cxMobile("name")}>{strings["titledName"]}</div>
                            {strings["ico"] ? (
                                <div className={cxMobile("ico")}>{strings["ico"]}</div>
                            ) : null}
                            {strings["dic"] ? (
                                <div className={cxDesktop("dic")}>{strings["dic"]}</div>
                            ) : null}
                            <br />
                            {strings["country"] ? (
                                <div>{strings["country"]}</div>
                            ) : null}
                            <div>{strings["address"]}</div>
                            <div>
                                <a className={cxMobile("phone-link")} href="tel:+420723812041">{strings["phoneNumber"]}</a>
                            </div>
                            <div>
                                <a className={cxMobile("mail-link")} href="mailto:marie@fytopatolog.cz">{strings["mail"]}</a>
                            </div>
                        </div>
                    </div>
                </MobileMenu>
            </div>
        }
    />
);
