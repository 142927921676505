import React, { FunctionComponent } from "react";

import { bem } from "../../classnames";
import { Header } from "../../components";
import { strings } from "../../translations";
import img from "./assets/profile.jpeg"
import "./Profile.scss";

const cx = bem("desktop-profile");

export const DesktopProfile: FunctionComponent = () => (
    <div className={cx()}>
        <Header text={strings["profile"]} />
        <div className={cx("content")}>
            <img
                alt="Fotografie Machanderové"
                className={cx("image")}
                src={img}
            />
            <div className={cx("right_panel")}>
                <div className={cx("right_panel_content")}>
                    <p>
                        {strings["profileMainText"]}
                    </p>
                    <br />
                    <p>
                        {strings["profileSecondaryText"]}
                    </p>
                    <br />
                    <br />
                    <h3>{strings["references"].toUpperCase()}:</h3>
                    {strings["referencesList"].map((reference) => (
                        <div className={cx("reference")}>
                            {reference.title}:<br />
                            <div className={cx("second-line")}>– {reference.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);
