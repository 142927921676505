import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { DesktopLogo } from "../Logo";
import "./Header.scss";

export type HeaderProps = {
    text: string;
}

export const Header: FunctionComponent<HeaderProps> = ({ text }) => (
    <div className="header">
        <DesktopLogo />
        <Link to="/">
            <h1 className="header__title">{text}</h1>
        </Link>
    </div>
);
