import React from "react";

import { Translation } from "./types";
import { general } from "./general";
import { NON_BREAKING_SPACE } from "./constants";

export const german: Translation = {
    profile: "Profil",
    services: `Mein${NON_BREAKING_SPACE}service`,
    priceList: "Preisliste",
    contact: "Kontakt",
    phytopathologist: "Phytopathologin",
    nameText: "Marie Machanderova",
    footerText: `© Marie Machanderova; Die${NON_BREAKING_SPACE}Website verwendet keine Cookies.`,
    authorText: "Pavel Pospíšil",
    titledName: `Dr.${NON_BREAKING_SPACE}Marie Machanderova`,
    ico: undefined,
    dic: undefined,
    country: "Tschechische Republik",
    references: "Referenzen",
    referencesList: [
        {
            title: "ABIES zahrada, park, zeleň (Schloss Dobříš)",
            description: `Kontrolle des${NON_BREAKING_SPACE}Gesundheitszustands von${NON_BREAKING_SPACE}Buchsbaumbeständen und${NON_BREAKING_SPACE}Beratungstätigkeit im${NON_BREAKING_SPACE}Pflanzenschutz`,
        },
        {
            title: "GARPEN ZAHRADNICKA spol. s r.o.",
            description: `Gesundheitskontrolle der${NON_BREAKING_SPACE}neugepflanzten Solitärbäume`,
        },
        {
            title: `GHMP (Schloss von${NON_BREAKING_SPACE}Troja)`,
            description: `regelmäßige Kontrolle des${NON_BREAKING_SPACE}Gesundheitszustands von${NON_BREAKING_SPACE}Zier- und${NON_BREAKING_SPACE}Obstgehölzen`,
        },
        {
            title: "NPÚ (Květná zahrada Kroměříž)",
            description: `regelmäßige Kontrolle des${NON_BREAKING_SPACE}Gesundheitszustands von${NON_BREAKING_SPACE}Buchsbaumbeständen und${NON_BREAKING_SPACE}Beratungstätigkeit im${NON_BREAKING_SPACE}Pflanzenschutz`,
        },
        {
            title: "NPÚ (Schloss Libochovice)",
            description: `Kontrolle des${NON_BREAKING_SPACE}Gesundheitszustands von${NON_BREAKING_SPACE}Buchsbaumbeständen und${NON_BREAKING_SPACE}Beratungstätigkeit im${NON_BREAKING_SPACE}Pflanzenschutz`,
        },
        {
            title: "ŠKOLKY MONTANO s.r.o.",
            description: `regelmäßige Kontrolle des${NON_BREAKING_SPACE}Gesundheitszustands von${NON_BREAKING_SPACE}Baumschulkulturen`,
        },
        {
            title: "PASIČ VLASTIMIL",
            description: `regelmäßige Kontrolle des${NON_BREAKING_SPACE}Gesundheitszustands von${NON_BREAKING_SPACE}Zierpflanzenkulturen unter Glas`,
        },
    ],
    profileMainText: `Ich absolvierte meine Promotion im${NON_BREAKING_SPACE}Bereich Phytomedizin an${NON_BREAKING_SPACE}der Mendel-Universität in${NON_BREAKING_SPACE}Brünn. Das${NON_BREAKING_SPACE}Wissen aus${NON_BREAKING_SPACE}dem Studium erweiterte ich${NON_BREAKING_SPACE}durch theoretische und${NON_BREAKING_SPACE}praktische Erfahrungen, durch Praktika in${NON_BREAKING_SPACE}Forschungsinstituten und${NON_BREAKING_SPACE}Laboren in${NON_BREAKING_SPACE}Deutschland, USA, Chile und${NON_BREAKING_SPACE}in${NON_BREAKING_SPACE}tschechischen Baumschulen. Zum Beispiel war ich mehrfach beim Baumschul-Beratungsring Weser-Ems e.V. tätig.`,
    profileSecondaryText: (
        <span>Meine Doktorarbeit hatte Krankheitserreger an&nbsp;Buchsbaum und&nbsp;Maßnahmen dagegen zum&nbsp;Thema.</span>
    ),
    serviceList: [
        {
            title: "Diagnose",
            description: `Beprobung symptomatischer Pflanzen, Bestimmung von${NON_BREAKING_SPACE}Krankheitserregern und${NON_BREAKING_SPACE}Schädlingen an${NON_BREAKING_SPACE}Freiland-, Container- und${NON_BREAKING_SPACE}Zierpflanzen mit${NON_BREAKING_SPACE}Labormethoden`,
        },
        {
            title: "Pflanzenschutz",
            description: `Empfehlungen für die${NON_BREAKING_SPACE}Verwendung von${NON_BREAKING_SPACE}geeigneten Pflanzenschutzmitteln und${NON_BREAKING_SPACE}biologischen Präparaten`,
        },
        {
            title: "Boden",
            description: `Probenahme und${NON_BREAKING_SPACE}Auswertung von${NON_BREAKING_SPACE}Untersuchungsergebnissen zu${NON_BREAKING_SPACE}pH-Wert und${NON_BREAKING_SPACE}Nährstoffen`,
        },
        {
            title: "Gießwasser",
            description: `Probenahme und${NON_BREAKING_SPACE}Auswertung von${NON_BREAKING_SPACE}Untersuchungsergebnissen zu${NON_BREAKING_SPACE}Qualität und${NON_BREAKING_SPACE}Nährstoffgehalten von${NON_BREAKING_SPACE}Gießwasser`,
        },
        {
            title: "Gesundheitskontrolle",
            description: `von Pflanzen im${NON_BREAKING_SPACE}Garten- und${NON_BREAKING_SPACE}Landschaftsbau sowie Garten- und${NON_BREAKING_SPACE}Parkanlagen`,
        },
    ],
    priceLineList: [
        { title: `Betriebskontrolle und${NON_BREAKING_SPACE}Erstellung von${NON_BREAKING_SPACE}Berichten nach Zeitaufwand.`, priceItemList: [] },
        { title: "", priceItemList: [] },
        {
            title: "Pflanzenproben Diagnose", priceItemList: [
                { price: "10,00", priceUnit: "€/Stk." },
            ]
        },
        {
            title: `Isolierung von${NON_BREAKING_SPACE}pilzlichen Krankheitserregern`, priceItemList: [
                { price: "35,00", priceUnit: "€/Stk." },
            ]
        },
        {
            title: <span><i>Phytophthora</i>&nbsp;&nbsp;Schnelltest</span>, priceItemList: [
                { price: "20,00", priceUnit: "€/Stk." },
            ]
        },
        { title: "", priceItemList: [] },
        { title: `Boden- und${NON_BREAKING_SPACE}Wasseruntersuchung nach Umfang.`, priceItemList: [] },
    ],
    address: general.address,
    phoneNumber: general.phoneNumber,
    mail: general.mail,
    mobilePriceList: [
        { title: `Betriebskontrolle und${NON_BREAKING_SPACE}Erstellung von${NON_BREAKING_SPACE}Berichten nach Zeitaufwand.` },
        { title: "Pflanzenproben Diagnose", priceText: "12,00 €" },
        { title: `Isolierung von${NON_BREAKING_SPACE}pilzlichen Krankheitserregern`, priceText: "35,00 €" },
        { title: <span><i>Phytophthora</i>&nbsp;&nbsp;Schnelltest</span>, priceText: "20,00 €" },
        { title: `Boden- und${NON_BREAKING_SPACE}Wasseruntersuchung nach Umfang.` },
    ],
}
