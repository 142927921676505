import { Translation } from "./types";
import { czech } from "./czech";
import { english } from "./english";
import { german } from "./german";

const LANGUAGE_STORAGE_KEY = "language";

export const setLanguageKey = (languageKey: string, reload: boolean = true) => {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, languageKey);
    if (reload) {
        window.location.reload();
    }
};

const getBrowserLanguage = () => (navigator.languages && navigator.languages[0]) || navigator.language;

const getBrowserLanguageKey = (): string => {
    const browserLanguage = getBrowserLanguage();

    if (browserLanguage.includes("cs") || browserLanguage.includes("sk")) {
        return CZECH_KEY;
    } else if (browserLanguage.includes("de")) {
        return GERMAN_KEY;
    }

    return ENGLISH_KEY;
}

export const getLanguageKey = () => localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? getBrowserLanguageKey() ?? ENGLISH_KEY;

export const CZECH_KEY = "czech";

export const GERMAN_KEY = "german";

export const ENGLISH_KEY = "english";

export const translations: { [languageKey: string]: Translation } = {
    [CZECH_KEY]: czech,
    [GERMAN_KEY]: german,
    [ENGLISH_KEY]: english
}

export const strings = translations[getLanguageKey()];
