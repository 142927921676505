import React, { FunctionComponent } from "react";

import { getLanguageKey, setLanguageKey, CZECH_KEY, ENGLISH_KEY, GERMAN_KEY } from "../../translations";
import "./LanguageDesktopMenuItem.scss";

export const LanguageDesktopMenuItem: FunctionComponent = () => {
    const selectedLanguageKey = getLanguageKey();

    const changeLanguage = (languageKey: string) => {
        setLanguageKey(languageKey)
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            {selectedLanguageKey !== CZECH_KEY ? (
                <>
                    <div
                        className="language-desktop-menu-item__language"
                        style={{ cursor: "pointer" }}
                        onClick={() => changeLanguage(CZECH_KEY)}
                    >
                        CS
                    </div>
                    <div className="language-desktop-menu-item__delimiter">/</div>
                </>
            ) : null}
            {selectedLanguageKey !== GERMAN_KEY ? (
                <>
                    <div
                        className="language-desktop-menu-item__language"
                        style={{ cursor: "pointer" }}
                        onClick={() => changeLanguage(GERMAN_KEY)}
                    >
                        DE
                    </div>
                    {selectedLanguageKey !== ENGLISH_KEY ? (
                        <div className="language-desktop-menu-item__delimiter">/</div>
                    ) : null}
                </>
            ) : null}

            {selectedLanguageKey !== ENGLISH_KEY ? (
                <div
                    className="language-desktop-menu-item__language"
                    style={{ cursor: "pointer" }}
                    onClick={() => changeLanguage(ENGLISH_KEY)}
                >
                    EN
                </div>
            ) : null}
        </div>
    );
};
