import React, { FunctionComponent } from "react";

import { bem } from "../../classnames";
import { strings } from "../../translations";
import { ResponsiveContentSwitch } from "../ResponsiveContentSwitch";
import "./Footer.scss";

const cxDesktop = bem("footer-desktop");
const cxMobile = bem("footer-mobile");

const link = (
    <a
        target="_blank"
        href="http://pavelpo.cz/"
        rel="noopener noreferrer"
    >
        {strings["authorText"]}
    </a>
);

export const Footer: FunctionComponent = () => (
    <ResponsiveContentSwitch
        desktop={
            <footer className={cxDesktop()}>
                <div className={cxDesktop("cookies")}>{strings["footerText"]}</div>
                {link}
            </footer>
        }
        mobile={
            <footer className={cxMobile()}>
                <div className={cxMobile("cookies")}>{strings["footerText"]}</div>
                {link}
            </footer>
        }
    />
);
