import { FunctionComponent, useState, ReactElement } from "react";

type ResponsiveContentSwitchProps = {
    mobile: ReactElement;
    desktop: ReactElement;
}

export const ResponsiveContentSwitch: FunctionComponent<ResponsiveContentSwitchProps> = ({ mobile, desktop }) => {
    const mediaQueryList = window.matchMedia("(max-width: 1000px)");
    const [mediaMatches, setMediaMatches] = useState(mediaQueryList.matches);

    const handleQueryChange = (event: MediaQueryListEvent) => {
        if (event.matches !== mediaMatches) {
            setMediaMatches(event.matches);
        }
    }

    // IE 11 do not support addEventListener
    if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener("change", handleQueryChange);
    }

    return mediaMatches ? mobile : desktop;
};
